<script>
import CitiesJSON from '@/utils/data/cities';
import CountriesJSON from '@/utils/data/countries';
import gql from 'graphql-tag';
import cloneDeep from 'lodash/cloneDeep';
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex'


import ListModule from './module/List.vue'
import modules from './ModuleList.vue'

const shp = require('shpjs')

export default {
  components: {
    ListModule,
    modules,
  },

  props: ['id'],

  data() {
    return {
      loading: false,
      valid: true,
      currentTab: null,
      dbf: [] ,
      customers: [],
      shp: [],
      file: [],
      shpName: '',
      dbfName: '',
      desactivatedFarm: false,
      citiesData: CitiesJSON,
      countriesData: CountriesJSON,
      form: {
        country: 'Brasil'
      },


      validations: {
        name: [(v) => !!v || 'Nome é obrigatório'],
        code: [(v) => !!v || 'code é obrigatório'],
        city: [(v) => !!v || 'Município é obrigatório'],
        state: [(v) => !!v || 'UF é obrigatório'],
        customer: [(v) => !!v || 'Cliente é obrigatório'],
        country: [(v) => !!v || 'País é obrigatório'],
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['authStatus', 'user','isManager']),

    countries () {
      return this.countriesData.map(item => item.name)
    },

    states () {
      if (this.form.country !== 'Brasil') return ['Exterior']
      else return this.citiesData.map(item => item.uf)
    },

    cities () {
      if (this.form.state) {
        if (this.form.country === 'Brasil'){
          const state = this.citiesData.find(item => item.uf === this.form.state)
          if (state) return state.cities
        }
      }
      // return []
    },

    customerName () {
      if (this.form.customer_id) {
        const customer = this.customers.find(item => item.value === this.form.customer_id)
        if (customer) return customer.text
      }
      return null
    }
  },

  async created() {
    if (this.id !== 'new') {
      this.form = await this.fetch();
      delete this.form.__typename;
    } 

    this.customers = (await this.fetchCustomers()).map((item) => ({
      value: item.id,
      text: item.name,
    }));

    if (this.id !== 'new') {
      this.fetchFile()
    }

    // setTimeout(() => {
    //   // this.$route.params.id = '123123'
    //   this.$router.replace('/fazendas/f9c16036-9e4b-40c3-97d3-aedc0c2e7917')
    // }, 2000)
  },

  methods: {
    async save() {
      if (!this.$refs.form.validate()) return false;
      this.loading = true;
      try {
        if (this.id === 'new') {
          const id = await this.insert();
          this.id = id
          await this.readAndSaveFile()
          Swal.fire({
            icon: 'success',
            text: 'Registro criado com sucesso!',
            position: 'top-end',
            toast: true,
            showConfirmButton: false,
            timer: 3000,
          });
          this.$router.replace('/fazendas/' + id)
          this.currentTab = 'tab-modulos'
        } else {
          await this.update();
          await this.readAndSaveFile()
          Swal.fire({
            icon: 'success',
            text: 'Registro alterado com sucesso!',
            position: 'top-end',
            toast: true,
            showConfirmButton: false,
            timer: 3000,
          });
          this.$router.push('/fazendas');
        }
        
      } catch (e) {
        console.error(e)
      }
      this.loading = false;
    },

    async saveMapData(info) {
      let form = {
        created_by: this.user.id,
        updated_by: this.user.id,
        farm_id: this.id,
        geo_data: info.content,
        shp_file_name: info.shpName,
        dbf_file_name: info.dbfName
      }
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($geo: farm_map_data_insert_input!) {
              insert_farm_map_data_one(object: $geo) {
                id
              }
            }
          `,
          variables: {
            geo: form,
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Registro criado com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async readAndSaveFile() {
    if (this.shp && this.shp.name) {
        const toArrayBuffer = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        });
        const toArrayBufferDbf = dbf => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(dbf);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        });
        const buffer = await toArrayBuffer(this.shp)
        const bufferDbf = await toArrayBufferDbf(this.dbf)
        const geoJson = await shp.combine([shp.parseShp(buffer, /*optional prj str*/),shp.parseDbf(bufferDbf)]);
        let info = {
          content: JSON.stringify(geoJson),
          shpName: this.shp.name,
          dbfName: this.dbf.name
        }
        this.saveMapData(info)  
      }
    },

    async insert() {
      const farm = cloneDeep(this.form)
      delete farm.modules
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation ($farm: farms_insert_input!) {
            insert_farms_one(object: $farm) {
              id
            }
          }
        `,
        variables: {
          farm,
        },
      });
      return res.data.insert_farms_one.id
    },

    async update() {
      const farm = cloneDeep(this.form)
      delete farm.modules
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($id: uuid!, $farm: farms_set_input!) {
            update_farms_by_pk(pk_columns: { id: $id }, _set: $farm) {
              id
            }
          }
        `,
        variables: {
          id: this.id,
          farm: farm,
        },
      });
    },

    async handleRefreshPage() {
      this.form = await this.fetch();
      delete this.form.__typename;
    },

    async fetch() {
      try {
        const result = await this.$apollo.query({
          query: gql`
            query farms($id: uuid!) {
              farm: farms_by_pk(id: $id) {
                name
                address
                city
                state
                country
                main_species_produced
                customer_id
                desactivated
                modules(order_by: {code: asc}) {
                  id
                  name
                  code
                  desired_use
                  irrigation
                  fertilized
                  type_use
                  description
                  farm_id
                  factor_adjust_fdn
                  factor_adjust_pb
                  factor_adjust_em
                  desactivated
                  temporary
                  created_at
                  start_date
                  paddocks_aggregate {
                    aggregate {
                      sum {
                        area
                      }
                    }
                  }
                  paddocks(order_by: {code: asc}) {
                    id
                    code
                    name
                    description
                    area
                    irrigation
                    fertilized
                    type_use
                    module_id
                    customer_id
                    desactivated
                    ref_module_id
                    paddock_cultivars{
                      customer_id
                      cultivar_id
                      percentage
                    }
                  }
                }
              }
            }
          `,
          variables: {
            id: this.id,
          },
          fetchPolicy: 'no-cache',
        });
        const farm = result.data.farm
        this.desactivatedFarm = farm.desactivated
        if (farm && farm.modules && farm.modules.length > 0) {
          for (let index = 0; index < farm.modules.length; index++) {
            const paddocks = await this.$apollo.query({
            query: gql`
              query paddocks($module_id: uuid! ) {
                paddocks(
                  where: { 
                    _or: [
                      { ref_module_id: {_eq: $module_id } }, 
                      { module_id: {_eq: $module_id} }
                    ]
                  }  order_by: { created_at: asc }
                ) {
                    id
                    code
                    name
                    description
                    area
                    irrigation
                    fertilized
                    type_use
                    module_id
                    module{
                      name
                    }
                    customer_id
                    desactivated
                    ref_module_id
                    ref_module{
                      id
                      name
                    }
                    paddock_cultivars{
                      customer_id
                      cultivar_id
                      percentage
                    }
                  }
              }
            `,
            variables: {
              module_id: farm.modules[index].id,
            },
            fetchPolicy: 'no-cache',
            });
          farm.modules[index].paddocks = paddocks.data.paddocks
          }
        }
        return result.data.farm;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchCustomers() {
      try {
        const result = await this.$apollo.query({
          query: gql`
            query customers {
              customers(order_by: [{ name: asc }]) {
                id
                name
              }
            }
          `,
          fetchPolicy: 'no-cache',
        });
        return result.data.customers;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchFile() {
      try {
        const result = await this.$apollo.query({
          query: gql`
            query farm_map_data($farm_id: uuid!) {
              farm_map_data(
                where: { ,
                  farm_id: {_eq: $farm_id}
                  deleted_at: {_is_null: true}
                },
                order_by: [{ created_at: desc }], limit: 1) {
                shp_file_name
                dbf_file_name
                id
              }
            }
          `, 
          variables: {
            farm_id: this.id
          },
          fetchPolicy: 'no-cache',
        });
        if (result && result.data && result.data.farm_map_data && result.data.farm_map_data[0]) {
          this.file = result.data.farm_map_data[0]
          this.shpName  = this.file.shp_file_name
          this.dbfName = this.file.dbf_file_name
        } else {
          this.file = null
          this.shpName = null
          this.dbfName = null
        }
      } catch (e) {
        console.error(e)
      }
    },

    async removeLastFile() {
      try {
        Swal.fire({
          title: 'Deseja apagar o Arquivo Salvo?',
          text: 'Você não poderá reverter isso!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          confirmButtonText: 'Sim, apague!',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.value) {
            await this.$apollo.mutate({
              mutation: gql`
                mutation ($id: uuid! $date: timestamptz) {
                  update_farm_map_data_by_pk(
                    pk_columns: { id: $id },
                    _set: {
                      deleted_at: $date
                    }
                  ) {
                    id
                  }
                }
              `,
              variables: {
                id: this.file.id,
                date: new Date()
              },
            });
            await this.fetchFile()
            Swal.fire({
              icon: 'success',
              text: 'Arquivo Excluido com sucesso!',
              position: 'top-end',
              toast: true,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
      } catch(e) {
        console.log(e)
        Swal.fire({
            icon: 'error',
            text: 'Erro ao excluir arquivo!',
            position: 'top-end',
            toast: true,
            showConfirmButton: false,
            timer: 3000,
          });
      }
    } 
  },
};
</script>

<template>
  <div>
    <h1 class="mb-5">Fazenda</h1>

    <v-card>
      <v-tabs v-model="currentTab" color="red darken-4" :vertical="$vuetify.breakpoint.xs? true: false">
        <v-tab href="#tab-dados">Dados</v-tab>
        <v-tab :disabled="id === 'new'" href="#tab-modulos">Módulos/Piquetes</v-tab>
        <v-tab :disabled="id === 'new'" href="#tab-ajustes">Fatores de Ajuste</v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab">
        <v-tab-item value="tab-dados">
          <v-card>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="save">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-autocomplete
                      v-model="form.customer_id"
                      :items="customers"
                      :rules="validations.customer"
                      :name="Math.random()"
                      :disabled="id !== 'new'"
                      autocomplete="new-password"
                      label="Cliente"
                      outlined
                      persistent-placeholder
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="form.name"
                      label="Nome da fazenda"
                      autocomplete="new-password"
                      :disabled="!isManager"
                      :name="Math.random()"
                      :rules="validations.name"
                      outlined
                      persistent-placeholder
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-autocomplete
                      v-model="form.main_species_produced"
                      :items="['Bovinos', 'Bubalinos']"
                      :name="Math.random()"
                      autocomplete="new-password"
                      :disabled="!isManager"
                      label="Principal espécie produzida"
                      outlined
                      persistent-placeholder
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-autocomplete
                      v-model="form.country"
                      :items="countries"
                      :rules="validations.country"
                      :name="Math.random()"
                      label="País"
                      :disabled="!isManager"
                      autocomplete="new-password"
                      outlined
                      persistent-placeholder
                    />
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-autocomplete
                      v-model="form.state"
                      :items="states"
                      :rules="validations.state"
                      :name="Math.random()"
                      label="UF"
                      :disabled="!isManager"
                      autocomplete="new-password"
                      outlined
                      persistent-placeholder
                    />
                  </v-col>

                  <v-col v-if="form.country !== 'Brasil'" cols="12" md="4">
                    <v-text-field
                      v-model="form.city"
                      :rules="validations.city"
                      :name="Math.random()"
                      label="Município"
                      :disabled="!isManager"
                      outlined
                      persistent-placeholder
                    />
                  </v-col>
                  <v-col v-else cols="12" md="4">
                    <v-autocomplete
                      v-model="form.city"
                      :items="cities"
                      :rules="validations.city"
                      :name="Math.random()"
                      label="Município"
                      :disabled="!isManager"
                      autocomplete="new-password"
                      outlined
                      persistent-placeholder
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="form.address"
                      label="Endereço"
                      autocomplete="new-password"
                      :name="Math.random()"
                      :disabled="!isManager"
                      outlined
                      persistent-placeholder
                    />
                  </v-col>

                  <v-col v-if="isManager" cols="12" md="4">
                    <v-file-input
                      ref="file"
                      v-model="shp"
                      label="Arquivo .SHP"
                      placeholder="Clique para selecionar seu arquivo"
                      accept=".shp"
                      name="file"
                      prepend-icon=""
                      append-icon="mdi-paperclip"
                      outlined
                      persistent-placeholder
                    />
                    <span> {{ shpName }} </span>
                  </v-col>
                  <v-col v-if="isManager" cols="12" md="4">
                    <v-file-input
                      ref="file"
                      v-model="dbf"
                      label="Arquivo .DBF"
                      placeholder="Clique para selecionar seu arquivo"
                      accept=".dbf"
                      name="file"
                      prepend-icon=""
                      append-icon="mdi-paperclip"
                      outlined
                      persistent-placeholder
                    />
                    <span> {{ dbfName }} </span>
                  </v-col>
                  <v-col v-if="isManager" cols="12" md="4">
                    <v-btn color="error" @click="removeLastFile">Remover</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <div class="d-flex pa-5">
              <v-spacer />
              <v-btn v-if="isManager" color="primary" plain class="mr-2" to="/fazendas">Cancelar</v-btn>
              <v-btn v-if="isManager" color="success" :loading="loading" :disabled="loading" @click="save"> Salvar </v-btn>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-modulos">
          <v-card>
            <v-card-text>
              <list-module
                v-model="form.modules"
                :farm-id="id"
                :farm-name="form.name"
                :customer-id="form.customer_id"
                :customer-name="customerName"
                :desactivated="desactivatedFarm"
                @refresh="handleRefreshPage"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-ajustes">
          <modules
            v-model="form.modules"
            :farm-id="id"
            :farm-name="form.name"
            :customer-id="form.customer_id"
            :customer-name="customerName"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
